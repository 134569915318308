import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "p-grid" }
const _hoisted_4 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_5 = { for: "name" }
const _hoisted_6 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_7 = { class: "p-field p-col-8 p-fluid" }
const _hoisted_8 = { for: "surname" }
const _hoisted_9 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_10 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_11 = { for: "gender" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_14 = { for: "birthdate" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "p-field p-col-4 p-fluid p-disabled" }
const _hoisted_17 = { for: "age" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "p-grid" }
const _hoisted_20 = { class: "p-field p-col-8 p-fluid p-disabled" }
const _hoisted_21 = { for: "email" }
const _hoisted_22 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_23 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_24 = { for: "phone" }
const _hoisted_25 = { class: "p-field p-col-8 p-fluid" }
const _hoisted_26 = { for: "address" }
const _hoisted_27 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_28 = { for: "postalCode" }
const _hoisted_29 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_30 = { for: "city" }
const _hoisted_31 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_32 = { for: "country" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HealzInput = _resolveComponent("HealzInput")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_CardComponent, {
    cardTitle: _ctx.$t('common.basicData'),
    "edit-mode": _ctx.editMode
  }, {
    headingActions: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_Button, {
        class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editMode = !_ctx.editMode))
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: "icon-edit",
            "aria-label": _ctx.$t('common.edit'),
            "aria-hidden": "true"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["class"])), [
        [
          _directive_tooltip,
          _ctx.$t('common.edit'),
          void 0,
          { bottom: true }
        ]
      ])
    ]),
    cardContent: _withCtx(() => [
      _createVNode(_component_TabView, null, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('user.profile'),
            disabled: _ctx.editMode
          }, {
            default: _withCtx(() => [
              (_ctx.patientInfo)
                ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('person.name')) + " " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
                        _createVNode(_component_HealzInput, {
                          id: "name",
                          modelValue: _ctx.patientInfo.name,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.patientInfo.name) = $event)),
                          modelModifiers: { trim: true },
                          editable: _ctx.editMode,
                          class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validatedPatientProfileForm.name.valid })
                        }, null, 8, ["modelValue", "editable", "class"]),
                        (_ctx.showValidation && !_ctx.validatedPatientProfileForm.name.valid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.$t(`${_ctx.validatedPatientProfileForm.name.message}`)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('person.surname')) + " " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
                        _createVNode(_component_HealzInput, {
                          id: "surname",
                          modelValue: _ctx.patientInfo.surname,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.patientInfo.surname) = $event)),
                          modelModifiers: { trim: true },
                          editable: _ctx.editMode,
                          class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validatedPatientProfileForm.surname.valid })
                        }, null, 8, ["modelValue", "editable", "class"]),
                        (_ctx.showValidation && !_ctx.validatedPatientProfileForm.surname.valid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.$t(`${_ctx.validatedPatientProfileForm.surname.message}`)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('patient.sex')), 1),
                        (!_ctx.patientInfo.gender && !_ctx.editMode)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_12, "-"))
                          : (_openBlock(), _createBlock(_component_Dropdown, {
                              key: 1,
                              id: "gender",
                              modelValue: _ctx.patientInfo.gender,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.patientInfo.gender) = $event)),
                              options: _ctx.genderOptions,
                              "option-label": "label",
                              "option-value": "value",
                              class: _normalizeClass(_ctx.editMode ? '' : 'no-editable')
                            }, null, 8, ["modelValue", "options", "class"]))
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('person.birthdate')), 1),
                        (!_ctx.patientInfo.birthdate && !_ctx.editMode)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_15, "-"))
                          : (_openBlock(), _createBlock(_component_Calendar, {
                              key: 1,
                              id: "birthdate",
                              modelValue: _ctx.patientInfo.birthdate,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.patientInfo.birthdate) = $event)),
                              "year-navigator": true,
                              "year-range": `${_ctx.minRangeYearBirth}:${_ctx.maxRangeYearBirth}`,
                              class: _normalizeClass(_ctx.editMode ? '' : 'no-editable')
                            }, null, 8, ["modelValue", "year-range", "class"]))
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('person.age')), 1),
                        _createVNode(_component_HealzInput, {
                          id: "age",
                          modelValue: _ctx.age,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.age) = $event)),
                          class: "p-disabled",
                          editable: _ctx.editMode
                        }, null, 8, ["modelValue", "editable"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header", "disabled"]),
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('user.contact'),
            disabled: _ctx.editMode
          }, {
            default: _withCtx(() => [
              (_ctx.patientInfo)
                ? (_openBlock(), _createElementBlock("form", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('person.email')) + " " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
                        _createVNode(_component_HealzInput, {
                          id: "email",
                          modelValue: _ctx.patientInfo.email,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.patientInfo.email) = $event)),
                          modelModifiers: { trim: true },
                          type: "email",
                          class: _normalizeClass(["p-disabled", { 'p-invalid': _ctx.showValidation && !_ctx.validatedPatientContactForm.email.valid }]),
                          editable: _ctx.editMode
                        }, null, 8, ["modelValue", "class", "editable"]),
                        (_ctx.showValidation && !_ctx.validatedPatientContactForm.email.valid)
                          ? (_openBlock(), _createElementBlock("small", _hoisted_22, _toDisplayString(_ctx.$t(`${_ctx.validatedPatientContactForm.email.message}`)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t('person.phone')), 1),
                        _createVNode(_component_HealzInput, {
                          id: "phone",
                          modelValue: _ctx.patientInfo.phoneNumber,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.patientInfo.phoneNumber) = $event)),
                          modelModifiers: { number: true },
                          type: "tel",
                          editable: _ctx.editMode
                        }, null, 8, ["modelValue", "editable"])
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t('person.address')), 1),
                        _createVNode(_component_HealzInput, {
                          id: "address",
                          modelValue: _ctx.patientInfo.address,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.patientInfo.address) = $event)),
                          modelModifiers: { trim: true },
                          editable: _ctx.editMode
                        }, null, 8, ["modelValue", "editable"])
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('person.postalCode')), 1),
                        _createVNode(_component_HealzInput, {
                          id: "postalCode",
                          modelValue: _ctx.patientInfo.postalCode,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.patientInfo.postalCode) = $event)),
                          editable: _ctx.editMode
                        }, null, 8, ["modelValue", "editable"])
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t('person.city')), 1),
                        _createVNode(_component_HealzInput, {
                          id: "city",
                          modelValue: _ctx.patientInfo.city,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.patientInfo.city) = $event)),
                          modelModifiers: { trim: true },
                          editable: _ctx.editMode
                        }, null, 8, ["modelValue", "editable"])
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('person.country')), 1),
                        _createVNode(_component_HealzInput, {
                          id: "country",
                          modelValue: _ctx.patientInfo.country,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.patientInfo.country) = $event)),
                          modelModifiers: { trim: true },
                          editable: _ctx.editMode
                        }, null, 8, ["modelValue", "editable"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header", "disabled"])
        ]),
        _: 1
      })
    ]),
    footerActions: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-secondary p-button-medium",
        label: _ctx.$t('common.cancel'),
        onClick: _ctx.onCancelEdition
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        class: "p-button p-button-info p-button-medium",
        loading: _ctx.submitting,
        label: _ctx.$t('common.save'),
        onClick: _ctx.onSubmit
      }, null, 8, ["loading", "label", "onClick"])
    ]),
    _: 1
  }, 8, ["cardTitle", "edit-mode"]))
}