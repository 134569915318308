
import { computed, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import PatientInformationCard from '@/components/PatientInformationCard.vue';
import { Patient } from '@/models/Patient';
import { medicalTestService } from '@/services/MedicalTestService';
import { useRoute } from 'vue-router';
import { ServiceError } from '@/services/util/ServiceError';
import MedicalTestList from './MedicalTestList.vue';
import MedicalTestCreateUpdateFormModal from './MedicalTestCreateUpdateFormModal.vue';
import { MedicalTestState, MedicalTestTableDto, MedicalTestTypeValue } from '@/models/MedicalTest';
import router from '@/router';

import CardComponent from '@/components/CardComponent.vue';

export default defineComponent({
  components: {
    HeadingComponent,
    CardComponent,
    PatientInformationCard,
    MedicalTestList,
    MedicalTestCreateUpdateFormModal,
  },
  emits: ['patient-changed'],
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const medicalTests: Ref<MedicalTestTableDto[] | undefined> = ref();
    const unfinishedMedicalTest = computed(
      () => medicalTests.value?.filter((test: MedicalTestTableDto) => test.state !== MedicalTestState.FINISHED) || [],
    );
    const newMedicalTestModalRef = ref();

    const fetchMedicalTest = async () => {
      const result = await medicalTestService.findAll(props.patient.id);
      if (!(result instanceof ServiceError)) {
        medicalTests.value = [...result];
      }
    };

    onMounted(async () => {
      await fetchMedicalTest();
    });

    const onNewMedicalTest = () => {
      newMedicalTestModalRef.value?.openDialog();
    };

    const onSeeDetailClicked = async (medicalTest: MedicalTestTableDto) => {
      if (medicalTest.medicalTestTypeValue == MedicalTestTypeValue.CALORIMETRY) {
        await router.push(`/patient/${props.patient.id}/medical-test/calorimetry/${medicalTest.id}`);
      }

      if (medicalTest.medicalTestTypeValue == MedicalTestTypeValue.CGM) {
        await router.push(`/patient/${props.patient.id}/medical-test/cgm/${medicalTest.id}/follow-up`);
      }
    };

    return {
      newMedicalTestModalRef,
      route,
      medicalTests,
      unfinishedMedicalTest,
      onNewMedicalTest,
      fetchMedicalTest,
      onSeeDetailClicked,
    };
  },
});
