import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "l-container"
}
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-col-7" }
const _hoisted_4 = { class: "p-col-5" }
const _hoisted_5 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_PatientInformationCard = _resolveComponent("PatientInformationCard")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MedicalTestList = _resolveComponent("MedicalTestList")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _component_MedicalTestCreateUpdateFormModal = _resolveComponent("MedicalTestCreateUpdateFormModal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : ''),
      "heading-icon": "icon-info_circle_outline",
      description: _ctx.$t('patients.information')
    }, null, 8, ["title", "description"]),
    (_ctx.patient)
      ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_PatientInformationCard, {
                patient: _ctx.patient,
                onPatientChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('patient-changed')))
              }, null, 8, ["patient"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_CardComponent, {
                cardTitle: _ctx.$t('medicalTest.scheduledMeasurements')
              }, {
                headingActions: _withCtx(() => [
                  _withDirectives((_openBlock(), _createBlock(_component_Button, {
                    class: "p-button-rounded p-button-only-icon p-button-only-icon--dark",
                    onClick: _ctx.onNewMedicalTest
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("i", {
                        class: "icon-plus",
                        "aria-label": _ctx.$t('medicalTest.new'),
                        "aria-hidden": "true"
                      }, null, 8, _hoisted_5)
                    ]),
                    _: 1
                  }, 8, ["onClick"])), [
                    [
                      _directive_tooltip,
                      _ctx.$t('medicalTest.new'),
                      void 0,
                      { bottom: true }
                    ]
                  ])
                ]),
                cardContent: _withCtx(() => [
                  _createVNode(_component_MedicalTestList, {
                    medicalTests: _ctx.unfinishedMedicalTest,
                    tableList: false,
                    onMedicalTestViewDetailClick: _ctx.onSeeDetailClicked
                  }, null, 8, ["medicalTests", "onMedicalTestViewDetailClick"])
                ]),
                _: 1
              }, 8, ["cardTitle"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_MedicalTestCreateUpdateFormModal, {
      ref: "newMedicalTestModalRef",
      patient: _ctx.patient,
      medicalTestList: _ctx.unfinishedMedicalTest,
      onNewMedicalTest: _ctx.fetchMedicalTest
    }, null, 8, ["patient", "medicalTestList", "onNewMedicalTest"])
  ], 64))
}