
import dateFormat from '@/helpers/DateFormat.helper';
import HealzInput from '@/components/healz/ui/HealzInput.vue';
import { Patient, PatientInformationFormDto, updatePatientWithPatientsInformation } from '@/models/Patient';
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { Genders } from '@/models/Genders';
import { ServiceError } from '@/services/util/ServiceError';
import { patientService } from '@/services/PatientService';
import {
  patientProfileForm,
  patientContactForm,
  isPatientInfoProfileValid,
  isPatientContactValid,
} from '@/validation/patientForm';

import CardComponent from './CardComponent.vue';

export default defineComponent({
  components: { HealzInput, CardComponent },
  emits: ['patient-changed'],
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const patientInfo: Ref<PatientInformationFormDto> = ref(new PatientInformationFormDto(props.patient));
    const validatedPatientProfileForm = computed(() => patientProfileForm(patientInfo.value));
    const patientProfileValid = computed(() => isPatientInfoProfileValid(validatedPatientProfileForm.value));
    const validatedPatientContactForm = computed(() => patientContactForm(patientInfo.value));
    const patientContactValid = computed(() => isPatientContactValid(validatedPatientContactForm.value));
    const editMode = ref(false);
    const showValidation = ref(false);
    const submitting = ref(false);
    const minRangeYearBirth = ref('1920');
    const maxRangeYearBirth = ref(new Date().getFullYear());
    const genderOptions = ref([
      { label: t(`user.${Genders.Female}`), value: Genders.Female },
      { label: t(`user.${Genders.Male}`), value: Genders.Male },
    ]);

    const age = computed(() => dateFormat.calculateAge(patientInfo.value.birthdate));

    const onSubmit = async () => {
      showValidation.value = true;
      if (!patientProfileValid.value || !patientContactValid.value) {
        return;
      }

      await savePatient(updatePatientWithPatientsInformation(props.patient, patientInfo.value));
    };

    async function savePatient(patient: Patient) {
      submitting.value = true;
      const result = await patientService.modify(patient.id, patient);
      submitting.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditPatient')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        editMode.value = false;
        showValidation.value = false;
        emit('patient-changed');
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditPatient')}`, life: 3000 });
      }
    }

    const onCancelEdition = async () => {
      editMode.value = false;
      patientInfo.value = Object.assign({}, new PatientInformationFormDto(props.patient));
    };

    return {
      editMode,
      patientInfo,
      age,
      genderOptions,
      dateFormat,
      minRangeYearBirth,
      maxRangeYearBirth,
      submitting,
      showValidation,
      validatedPatientProfileForm,
      validatedPatientContactForm,
      onSubmit,
      onCancelEdition,
    };
  },
});
