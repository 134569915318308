import { PatientDto, PatientFormDto, PatientInformationFormDto } from '@/models/Patient';
import { ValidationResult, isObjectValid, required, validateEmail } from './form';

export interface CreatePatientFormValidity {
  name: ValidationResult;
  surname: ValidationResult;
  email: ValidationResult;
  language: ValidationResult;
  specialistIds: ValidationResult;
}

export function isCreatePatientFormValid(form: CreatePatientFormValidity) {
  return isObjectValid<CreatePatientFormValidity>(form);
}

export function createPatientForm(
  patient: PatientFormDto,
  patientList: PatientDto[] | undefined,
  ownerSpecialistIds: string[] | undefined,
): CreatePatientFormValidity {
  const name = required(patient.name);
  const surname = required(patient.surname);
  const email = validateEmail(
    patient.email,
    'messages.patient-exist',
    patientList?.map<string>((item: PatientDto) => item.email),
  );
  const specialistIds = required(ownerSpecialistIds);
  const language = required(patient.language);

  return {
    name,
    surname,
    email,
    specialistIds,
    language,
  };
}

export function updatePatientForm(patient: PatientFormDto): CreatePatientFormValidity {
  const name = required(patient.name);
  const surname = required(patient.surname);
  const email = required(patient.email);
  const specialistIds = required(patient.specialistIds);
  const language = required(patient.language);

  return {
    name,
    surname,
    email,
    specialistIds,
    language,
  };
}

export interface PatientProfileFormValidity {
  name: ValidationResult;
  surname: ValidationResult;
}

export function isPatientInfoProfileValid(form: PatientProfileFormValidity) {
  return isObjectValid<PatientProfileFormValidity>(form);
}

export function patientProfileForm(patient: PatientInformationFormDto): PatientProfileFormValidity {
  const name = required(patient.name);
  const surname = required(patient.surname);

  return {
    name,
    surname,
  };
}

export interface PatientConactFormValidity {
  email: ValidationResult;
}

export function isPatientContactValid(form: PatientConactFormValidity) {
  return isObjectValid<PatientConactFormValidity>(form);
}

export function patientContactForm(patient: PatientInformationFormDto): PatientConactFormValidity {
  const email = required(patient.email);

  return {
    email,
  };
}
